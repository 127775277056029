<template>
  <div>
    <h3 class="title-g">操作记录</h3>
    <table>
      <thead>
        <td v-for="(value, key) in tableList" :key="key">{{ value }}</td>
      </thead>
      <tr v-for="(item, index) in settingHistory" :key="index">
        <td v-for="(value, key) in tableList" :key="key">
          <div>{{ item[key] }}</div>
        </td>
      </tr>
    </table>
    <br />

    <el-pagination
      :page-size="15"
      :pager-count="9"
      :current-page.sync="page"
      layout="total, prev, pager, next"
      :total="total"
      hide-on-single-page
      @current-change="handleCurrentChange"
    ></el-pagination>
    <!-- 照片放大弹出框，remark是图片时 -->
    <el-dialog :visible.sync="picShow" width="900px">
      <img :src="imgForPreviw" width="800px" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getSettingHistory } from "@/api/api";
export default {
  data() {
    return {
      page: 1,
      count: 10,
      picShow: false,
      imgForPreviw: "",
      tableList: {
        settingSn: "流水号",
        objId: "对象ID",
        objName: "对象名称",
        setting: "操作",
        remark: "备注",
        genTime: "时间",
        operator: "操作人"
      },
      //分页
      total: 0,
      settingHistory: []
    };
  },
  mounted() {
    this.settinghistoryListFn();
  },
  methods: {
    settinghistoryListFn() {
      const that = this;
      getSettingHistory({
        page: that.page,
        count: that.count
      }).then(res => {
        if (res.result) {
          that.settingHistory = res.data.list;
          that.total = res.data.total;
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.settinghistoryListFn();
    },
  }
};
</script>

<style scoped>
td div {
  word-break: break-all;
  word-wrap: break-word;
  padding: 0px 5px;
  line-height: 25px;
  max-width: 200px;
}
</style>